/* global Component, axios, _  */
class invoiceActionsComponent extends Component {

    static name() {
        return "invoiceActionsComponent";
    }

    static componentName() {
        return "invoiceActionsComponent";
    }

    getProps() {
        return  ['data', 'index', 'column'];
    }

    data() {
        return {
            FavouritesOrders:[],
        };
    }

    getComputed() {
        return {
            orderSerNr:function (){
                return window.btoa(this.data.SerNr);
            }
        };
    }

    getMethods() {
        return {
            downloadDocument:this.downloadDocument,
        };
    }


    downloadDocument(){
        let params =  this.encode(`RecordName=Invoice&InternalId=${this.data.internalId}`);
        //console.log('custom-actions: ', this.data);
        window.open('../oo/api/get_pdfdocument?' + params,"_blank");
    }




    getTemplate() {
        return`<div :key="'actions-'+data.SerNr" class="btn-group custom-actions" role="group" aria-label="Button group with nested dropdown">
                  <div class="btn-group" role="group">
                      <a class="dropdown-item"  :key="'actions-download-'+data.SerNr"  target="_blank" :title="tr('Download')"  @click="downloadDocument()" >
                        <i class="fa fa-download"></i>
                      </a>
                    </div>
                </div>`;
    }
}

invoiceActionsComponent.registerComponent();

Vue.component('invoiceList', {
    template: `<div class="container-fluid pt-2 px-0 px-md-3">
                  <template v-if="$store.getters.getAppState=='ready'">
                        <div class="row justify-content-center">
                          <div class="col-12 px-0 px-md-3">
                            <h5 class="row title">
                              <span class="col-12 col-md-4 text-center text-md-left mb-2">{{tr("Invoice List")}}</span>
                            </h5>
                            <v-client-table class="custom-vue-table"  key="invoiceView-list" :columns="columns" :data="invoices" :options="options" :css="css" >
                              <div slot="afterFilter" class="VueTables__search-field">
                                <div class="input-group-append">
                                  <span class="input-group-text" id="basic-addon2"><i class="icon fas fa-search"></i></span>
                                </div>
                              </div>
                            </v-client-table>
                          </div>
                      </div>
                  </template>
                  <template v-else>
                      <div class="loading">
                          <i class="fas fa-circle-notch fa-spin fa-3x"></i>
                      </div>
                  </template>
              </div>`,
    data: function () {
        return {
            loading:false,
            invoices: [],
            css: {
                ascendingIcon: 'blue chevron up icon',
                descendingIcon: 'blue chevron down icon',
                table: {
                    loadingClass: 'loading',
                    ascendingIcon: 'blue chevron up icon',
                    descendingIcon: 'blue chevron down icon',
                }
            }
        };
    },
    beforeMount: function () {
        if(!this.$store.getters.canShowInvoiceListInProfile)
            this.$router.push("/profile/Orders");
    },
    async mounted() {
        await this.loadInvoices();
    },
    computed: {
        columns: function (){
            let basicColumns = ['SerNr', 'TransDate',"Status"];
            if(this.$store.getters.getPriceToShow)
                basicColumns.push("Total");
            else{
                basicColumns.push("SubTotal");
            }
            basicColumns.push("Actions");
            return basicColumns;
        },
        options: function () {
            let basiOption =  {
                preserveState: true,
                filterByColumn: false,
                filterable: ["SerNr", "TransDate"],
                dateColumns:["TransDate"],
                dateFormat:["DD/MM/YYYY"],
                multiSorting: {
                    name: [
                        {
                            column: 'SerNr',
                            matchDir: true
                        },
                        {
                            column: 'TransDate',
                            matchDir: true
                        },
                    ]
                },
                headings: {
                    'SerNr': this.tr('Invoice'),
                    'TransDate': this.tr('Date'),
                    'Status': this.tr('Status'),
                    'SubTotal': this.tr('Total') + " " + this.tr('VAT EXC'),
                    'Total': this.tr('Total') + " " + this.tr('VAT INC'),
                    'Actions': this.tr('Actions')
                },
                perPage: 25,
                pagination: {
                    chunk: 20,
                },
                templates: {
                    Actions: 'invoiceActionsComponent',
                    Status: `orderStatusComponent`
                },
                texts: {
                    count: `Mostrando desde {from} a {to} de {count} ${this.tr('Invoices')}|{count} ${this.tr('Invoices')}|One ${this.tr('Invoice')}`,
                    filter:  '',
                    limit: '',
                    filterPlaceholder: '',
                    noResults: this.tr('No Records found'),
                    page: this.tr('Page') + ":", // for dropdown pagination
                    filterBy: 'Filtrado por {column}', // Placeholder for search fields when filtering by column
                    loading: this.tr('Loading') + '...', // First request to server
                    defaultOption: 'Select {column}', // default option for list filters,
                },
                uniqueKey: "SerNr"
            };
            if(this.$store.getters.getPriceToShow)
                delete basiOption.headings['SubTotal'];
            else{
                delete basiOption.headings['Total'];
            }

            return basiOption;
        }
    },
    methods: {
        loadInvoices: function () {
            this.loading = true;
            let self = this;
            axios.get('/invoices/getInvoices?jsonFormatted=1')
                .then(function (response) {
                    self.error = false;
                    self.DocTypes = response.data.DocTypes;
                    self.InvoiceTypes = response.data.InvoiceTypes;
                    self.invoices = _.map(response.data.invoices, (x) => {
                        return x.fields;
                    });
                    this.loading = false;
                }).catch(function (error) {
                    self.error = true;
                });
        }
    }
});
